.container {
  margin: 30px;
  padding: 30px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.title {
  border-bottom: 2px solid darkgray;
  padding-bottom: 8px;
  margin-bottom: 8px;
}
header {
  display: flex;
  flex-direction: column;
}
h1,
h2,
h3 {
  margin: 0;
}
p,
a {
  margin: 4;
}
section {
  margin-top: 4%;
}
.indent {
  border-left: 2px solid darkgray;
  padding-left: 10px;
  margin-bottom: 10px;
}
